// Imports
@import "./variables.scss";
@import "./scrollbar.scss";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
// -----------------------------------------------------------------------------------------

/* ng-select  */
@import "~@ng-select/ng-select/themes/default.theme.css";

/* bs-stepper  */
@import '~bs-stepper/dist/css/bs-stepper.min.css';

html,
body {
    height: 100vh;
}
//Tags
h1 { font-size: 2rem; }

h2 { font-size: 1.8rem; }

h3 { font-size: 1.6rem; }

h4 { font-size: 1.4rem; }

h5 { font-size: 1.2rem; }

h6 { font-size: 1rem; }

label {
    font-weight: bold;
    margin-bottom:0.3rem !important;
}

a { text-decoration: none; }

// Configure dark modal that there is no border bug on the top
.modal-header.bg-dark {
    margin-top: -1px;
}

.cursor-pointer {
    cursor: pointer;
}

.ng-select.is-invalid .ng-select-container {
    border-color: red;
}

.sticky-toolbar {
    border-bottom: 0;
    top: $top-navbar-height;
    position: sticky;
    z-index: 1;
}

$spinner-size: 30px;
.loading-container {
    margin-top: -1em;
    height: 100vh;
    width: 100%;
    position: relative;
}
.loading-spinner {
    height: $spinner-size;
    width: $spinner-size;
    position: absolute;
    left: calc((100% - #{$spinner-size}) / 2);
    top: calc((100vh - #{$spinner-size}) / 2);
}
.loading-spinner {
    .spinner-grow {
        width: $spinner-size;
        height: $spinner-size;
    }
    .spinner-border {
        width: $spinner-size;
        height: $spinner-size;
    }
}